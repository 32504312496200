module.exports = {
  customProperties: {
      variables: {
          w_macMsg:          "Hi, Mr.Macintosh",
          w_windowMsg:       "Hi, Mr.Window",
          w_iphoneMsg:       "Hi, Traveller",
          w_androidMsg:      "Hello, Mr.Android",
          w_defaultMsg:      "We are Next Pixo",
          black:            "#111",
          green:            "#25b88d",
          orange:           "#dc661d",
          text_color:       "var(--black)",
          link_color:       "var(--green)",
          link_hover:       "var(--orange)",
          default_margin:   "1.5em",
          ease:             ".2s ease-in-out",
          easeslow:         ".6s ease-in",
          projectTypeOpt:   ['Web', 'Branding', 'Illustration'],
      },
  },
  customMedia: {
      extensions: {
          v_small:   "(min-width: 30em)",
          v_medium:  "(min-width: 48em)",
          v_large:   "(min-width: 64em)",
          v_xlarge:  "(min-width: 75em)",
          v_xxlarge: "(min-width: 90em)",
      },
  },
};
