
import React, { Component } from "react"
import Msg from "./settings"
import {UserAgentProvider, UserAgent} from "@quentin-sommer/react-useragent"
import {isMobile, isTablet, isIE, isBrowser, osName} from 'react-device-detect'

function splitLetter(_letters){
  const _letterStr =_letters.split('')
  let _contents = []
  for (let i = 0; i < _letterStr.length; i++) {
    if (_letterStr[i]==' ') {
      _contents.push('<span class="single-char blank-char char-' + i + '">' + _letterStr[i] + '</span>');
    }else{
      _contents.push('<span class="single-char char-' + i + '">' + _letterStr[i] + '</span>');
    }
  }

  return (
     <div dangerouslySetInnerHTML={{__html: _contents.join("")}}></div>
  )
}

function WelcomeMsgWrapper(props) {
  if (isMobile || isTablet) {
      return <WelcomeMsg platformType="mac" displayMsg={Msg.customProperties.variables.w_iphoneMsg} displayStatus={props.displayStatus} />
  }
  if (osName=='Mac OS'){
    return <WelcomeMsg platformType="mac" displayMsg={Msg.customProperties.variables.w_macMsg} displayStatus={props.displayStatus} />
  }
  return <WelcomeMsg platformType="mac" displayMsg={Msg.customProperties.variables.w_defaultMsg} displayStatus={props.displayStatus} />
}

function WelcomeMsg(props) {
  const platformType = props.platformType
  const displayMsg = props.displayMsg

  return (
    <h1 className={"welcome-msg" + (props.displayStatus ? ' activated':'')}>{splitLetter(displayMsg)}</h1>
  )
}

class IndexIntro extends React.Component {
  constructor(props) {
		super(props)
		this.state = {
      active: false,
    }
	}

  componentDidMount(){
    setTimeout(() => {
      this.setState({active:true})
    }, 1000)
  }

  render(){

    return(
      <dl>
      <dt>
        <WelcomeMsgWrapper displayStatus={this.state.active} />
      </dt>
      <dd>
        <p className="box-quote">Great things in business are never done by one person. They&#39;re done by a team of people.</p>
        <span className="author-name">Steve Jobs</span>
      </dd>
    </dl>

    )
  }
}


export default IndexIntro
