import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import LogoImg from "../../public/images/logo.gif"
import SEO from "../components/seo"
import IndexIntro from "../components/index-intro"
import {UserAgentProvider, UserAgent} from "@quentin-sommer/react-useragent"
import BtnGoContact from "../components/btnPageToContact"

const IndexPage = () => (<Layout>
  <SEO title="Home"/>
  <div className="wrapper starter">
    <div className="inner-wrap">
      <section>
        <Link to="/"><img src={LogoImg} className="logo ease"/></Link>
          <IndexIntro></IndexIntro>
          <div className="bar-btm">
  					<Link to="/works/" className="btn-enter btn-circle"><i className="fa fa-handshake-o" aria-hidden="true"></i></Link>
  				</div>
      </section>
    </div>
  </div>
  <BtnGoContact />
</Layout>)

export default IndexPage
