import React from "react"
import {Link} from "gatsby"
import { withGlobalState } from 'react-globally'

class BtnGoContact extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.props.setGlobalState({
      contactBackButtonDisplay: true,
    })
  }

  render() {

    return (
      <div className={"box-contact" + (this.props.displayStatus? ' hidden':'')}>
    		<Link to="/contact/" className="btn-email btn-circle"><i className="fa fa-envelope-o" aria-hidden="true"></i></Link>
    	</div>
    )
  }
}

export default withGlobalState(BtnGoContact)
